.body {
  background: radial-gradient(
    102.37% 29.37% at 27.64% 40.07%,
    #f2e3d3 0%,
    #fff9f0 100%
  ) !important;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.hide {
  display: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.renderer-components-field-content:has(.picturechoice__options) {
  max-width: 1300px !important;
  z-index: 9999 !important;
}
.disabled {
  pointer-events: none !important; /* Prevents all mouse events */
  opacity: 0.5 !important; /* Makes the div look grayed out */
  background-color: #f0f0f0 !important; /* Optional: give a different background color */
  color: #a0a0a0 !important; /* Optional: change text color to a gray shade */
  cursor: not-allowed !important; /* Changes cursor to indicate the div is not clickable */
  padding: 8px !important;
  width: 200px !important;
  text-align: center !important;
}
.renderer-components-form-footer {
  position: fixed !important;
  width: fit-content !important;
  bottom: 0 !important;
  right: 0 !important;
  left: auto !important;
}

.renderer-core-progress-bar__track {
  margin-top: 0 !important;
}

.two-choices {
  background-color: #282c34 !important;
}

.multipleChoice__optionWrapper {
  border: 1px solid #d9e6f2 !important;
  /* background: #f0f8ff !important; */
  box-shadow: 0px 4px 8px 0px rgba(201, 210, 217, 0.4) !important;
  min-width: 225px !important;
}

.fixed-logo {
  width: 109px;
  height: 72px;
  position: fixed;
  top: 0;
  padding-left: 80px;
  padding-top: 16px;
  /* left: 10px; */
  cursor: pointer;

  z-index: 99999; /* Ensures the logo is on top of other elements */
}
.right {
  right: 0px;
}
.left {
  left: 0px;
}
.language-switcher-container {
  z-index: 99999;
  position: absolute;
  top: 10px;
  right: 10px;
}

@media screen and (max-width: 769px) {
  .logo-container {
    display: none;
  }
}

.css-5u6yt2 {
  background: radial-gradient(
    102.37% 29.37% at 27.64% 40.07%,
    #f2e3d3 0%,
    #fff9f0 100%
  ) !important;
}
.css-1qfp1eu {
  border-radius: 8px !important;
  border: 1px solid #d8e6f2 !important;
  background: #eff7fd !important;
}

.css-1qfp1eu:hover {
  border: 1px solid #d8e6f2 !important;
  background: #cfe7f9 !important;
}
.css-1qfp1eu.selected {
  border: 1px solid #1d60c7 !important;
  background: #1d60c7 !important;
}

.css-1963e7w {
  border: 1px solid #d8e6f2 !important;
  background: #eff7fd !important;
}
.css-1963e7w:hover {
  border: 1px solid #d8e6f2 !important;
  background: #cfe7f9 !important;
}

.css-1963e7w.selected {
  border: 1px solid #1d60c7 !important;
  background: #1d60c7 !important;
}

.no-ok-button .is-visible {
  display: none;
}
