.language-switcher {
  display: flex;
  margin: 10px 0;
  position: relative;
}

.language-switcher select {
  all: unset;
  padding: 10px 14px;
  border: none;
  border-radius: 8px;
  background-color: transparent;
  cursor: pointer;
  transition: box-shadow 0.2s ease;
  font-size: 16px;
  color: #333;
  outline: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.language-switcher select:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.language-switcher select:focus {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
